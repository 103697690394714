<template>
  <div class="section">
    <Modal />
    <Header class="opaque" :opaque="true"/>
    <div class="content">
      <Breadcrumb :items="breadcrumbItems" />
      <div class="container">
        <div class="text-container">
          <div class="left">
            <h2>Contact Us</h2>
            <p>Please fill out the form below and we will get back to you as soon as possible.</p>
          </div>
          <div class="right">
            <form @submit.prevent="submit">
              <input
                type="text"
                autocomplete="name"
                placeholder="Name"
                required
                v-model="name"
              >
              <input
                type="email"
                autocomplete="email"
                placeholder="Email"
                required
                v-model="email"
              >
              <input
                type="tel"
                autocomplete="tel"
                placeholder="Number"
                required
                v-model="number"
              >
              <input
                type="text"
                autocomplete="organization"
                placeholder="Company"
                required
                v-model="company"
              >
              <input
                type="text"
                autocomplete="street-address"
                placeholder="Address"
                required
                v-model="address"
              >
              <Button type="submit">SEND</Button>
            </form>
            <p>By clicking the "Submit" button, you agree to process personal data and confirm
              that you are of legal age in accordance with the <span>terms</span>.</p>
          </div>
        </div>
        <div class="line"></div>
        <div class="map-container">
          <div class="left">
            <h2>Where To Find Us</h2>
            <div class="data-container">
              <div class="title">Location:</div>
              <div class="section-content">
                1444 N Farnsworth Ave <br>
                STE 504 <br>
                Aurora, IL 60505
              </div>
              <div class="section-content">
                6100 Lake Ellenor Drive <br>
                STE 174 <br>
                Orlando,FL 32809
              </div>
            </div>
            <div class="data-container">
              <div class="title">Phone:</div>
              <div class="section-content phone">
                <a href="tel:5133604974">513-360-4974</a> phone, 513-360-4971 fax
              </div>
            </div>
            <div class="social">
              <a href="">
                <FacebookIcon class="icon-shaking"/>
              </a>
              <a href="">
                <InstagramIcon class="icon-shaking"/>
              </a>
              <a href="">
                <TwitterIcon class="icon-shaking"/>
              </a>
            </div>
          </div>
          <!-- <GmapMap
            :center="{lat:41.787996, lng:-88.282028}"
            :zoom="18"
            map-type-id="terrain"
            class="map"
          >
          </GmapMap> -->
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1051.7767030084092!2d-88.28239063159762!3d41.78793430005975!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880efbaad440e6b5%3A0xa1460cff202141cc!2sThales%20Visionix%2C%20Inc!5e0!3m2!1sen!2sus!4v1606224369186!5m2!1sen!2sus"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
            class="map"
          ></iframe>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import Button from '@/components/Button.vue';
import Footer from '@/components/Footer.vue';
import FacebookIcon from '@/assets/icons/facebook.svg';
import InstagramIcon from '@/assets/icons/instagram.svg';
import TwitterIcon from '@/assets/icons/twitter.svg';
import axios from 'axios';
import Modal from '@/components/Modal.vue';

export default {
  components: {
    Header,
    Breadcrumb,
    Button,
    Footer,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    Modal,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          title: 'Home',
          to: '/',
        },
        {
          title: 'Contacts',
          to: '/contact-us',
        },
      ],
      name: '',
      email: '',
      number: '',
      company: '',
      address: '',
    };
  },
  methods: {
    async submit() {
      const {
        name, email, number, company, address,
      } = this;
      if (name && email && number && company && address) {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('number', number);
        formData.append('company', company);
        formData.append('address', address);
        try {
          const res = await axios.post('/contacts', formData);
          if (res.status === 200) {
            this.showModal();
          }
        } catch (e) {
          console.log('Error occured during form submit');
          console.error(e);
        }
      }
    },
    showModal() {
      document.getElementById('modalContainer').style.display = 'flex';
    },
  },
};
</script>

<style lang="sass" scoped>
.section
  position: relative
  .content
    height: calc(100% - 128px - 170px)
    width: 1520px
    margin: 0px auto
    padding-top: 60px
    .container
      display: flex
      flex-direction: column
      .text-container
        display: flex
        margin-top: 50px
        .left
          h2
          p
            line-height: 24px
            color: #262626
            width: 486px
            margin: 40px 0px 59px 0px
        .right
          margin-left: 182px
          form
            margin-top: 40px
            display: grid
            grid-template-columns: 1fr 1fr 1fr
            grid-template-rows: 1fr 1fr
            column-gap: 20px
            row-gap: 17px
            width: 790px
            input
              border: 2px solid #f0f0f0
              font-size: 14px
              color: #000000
              padding: 8px 18px
              width: 250px
              &::placeholder
                font-size: 14px
                color: #acacac
            button
              width: 250px
          p
            font-size: 10px
            line-height: 17px
            color: #acacac
            margin: 20px 0px 20px 0px
            width: 520px
            span
              color: var(--accent-color)
      .line
        width: 1520px
        height: 2px
        background-color: #f0f0f0
      .map-container
        display: flex
        margin-top: 20px
        .map
          margin-left: 95px
          width: clamp(100%, 100%, 938px)
          width: 938px
          height: 259px
        .left
          width: 487px
          h2
          .data-container
            display: flex
            margin-top: 29px
            .title
              font-weight: 700
              font-size: 18px
              line-height: 31px
              color: #262626
              margin-right: 30px
            .section-content
              line-height: 24px
              color: #acacac
              margin-right: 39px
              margin-top: 4px
              text-align: start
              &:last-child
                margin-right: 0px
              &.phone
                margin-left: 17px
                a
                  color: #acacac
          .social
            margin-top: 39px
            text-align: start
            margin-left: 111px
            a
              margin-right: 11px
              &:last-child
                margin-right: 0px
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  .section
    .content
      height: calc(100% - 80px - 120px)
      width: 1320px
      padding-top: 40px
      .container
        .text-container
          margin-top: 30px
          .left
            h2
            p
              width: 400px
              margin: 20px 0px 20px 0px
          .right
            margin-left: 50px
            form
              margin-top: 20px
              width: auto
        .line
          width: 100%
          height: 2px
        .map-container
          margin-top: 20px
          flex-direction: row
          .left
            .data-container
              margin-top: 15px
            .social
              margin-top: 30px
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .section
    background-color: #fff
    .content
      height: auto
      width: 100%
      max-width: 800px
      padding: 40px 40px 0px 40px
      .container
        .text-container
          flex-direction: column
          margin-top: 50px
          .left
            h2
            p
              width: 100%
              font-size: 20px
              line-height: 34px
              margin: 63px 0px 38px 0px
          .right
            margin-left: 0px
            margin-left: auto
            margin-right: auto
            form
              margin-top: 20px
              grid-template-columns: 1fr
              grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr
              row-gap: 18px
              width: auto
              input
                width: 100%
                font-size: 20px
                line-height: 34px
                padding: 14px 74px
                &::placeholder
                  font-size: 20px
                  line-height: 34px
              button
                justify-self: center
                width: 100%
                max-width: 306px
            p
              width: 100%
              font-size: 16px
              line-height: 24px
              margin: 50px 0px
        .line
          width: 100%
          height: 2px
        .map-container
          margin-top: 113px
          margin-bottom: 40px
          flex-direction: column
          .map
            margin-left: 0px
            margin-top: 115px
            width: 100%
            height: 622px
          .left
            width: 100%
            h2
              margin-bottom: 102px
            .data-container
              margin-top: 93px
              .title
                font-size: 26px
                line-height: 44px
              .section-content
                font-size: 20px
                line-height: 34px
                &.phone
                  margin-top: 8px
            .social
              margin-top: 90px
              width: auto
              margin-left: 0px
              display: flex
              justify-content: center
              a
                width: 69px
                height: 69px
                display: block
                svg
                  transform: scale(2.37)
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  .section
    background-color: #fff
    .content
      height: auto
      width: 100%
      padding: 40px 15px 0px 15px
      .container
        .text-container
          flex-direction: column
          margin-top: 30px
          .left
            h2
            p
              width: 100%
              margin: 20px 0px 20px 0px
          .right
            margin-left: 0px
            form
              margin-top: 20px
              grid-template-columns: 1fr
              grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr
              width: auto
              input
                width: 100%
                max-width: 300px
              button
                width: 100%
                max-width: 300px
            p
              width: 100%
        .line
          width: 100%
          height: 2px
        .map-container
          margin-top: 20px
          margin-bottom: 40px
          flex-direction: column
          .map
            margin-left: 0px
            margin-top: 30px
            width: 100%
            height: 259px
          .left
            width: 100%
            .data-container
              margin-top: 15px
            .social
              margin-top: 30px
              width: auto
              margin-left: 90px
              a
                width: 29px
                height: 29px
</style>
